import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "SanAntonio.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          classId="heroImg"
          fluid={imageData}
        >
          <Card className="text-center" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Welcome Home!</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Your ZTS Properties rental portal
              </Card.Subtitle>
              {/* <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text> */}
              <Button href="/account" variant="outline-primary">
                Log In
              </Button>
            </Card.Body>
          </Card>
          ;
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default StyledBackgroundSection

import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"
import StyledBackgroundSection from "../components/background"

export default () => (
  <div>
    <Header />
    <Navbar className="Menu" variant="light">
      <Navbar.Brand href="#home" className="logoBox">
        <img
          src="https://github.com/dougbluff/LogoStore/blob/master/Logo.PNG?raw=true"
          className="d-inline-block align-top logo"
          alt="ZTS Properties Logo"
        />
      </Navbar.Brand>
      <Nav className="ml-auto">
        <Button href="/account" variant="outline-primary">
          Log In
        </Button>
      </Nav>
    </Navbar>
    <div className="main">
      <StyledBackgroundSection />
    </div>
    <Footer />
  </div>
)
